<template>
  <div id="app">
  </div>
</template>

<script>
import iiifannotation from './components/iiifannotation.vue'
import storyboard from './components/storyboard.vue'

export default {
  name: 'app',
  components: {
    iiifannotation,
    storyboard
  }
}
</script>
